<template>
  <div>
    <div class="row">
      <loading-notification :show="loading" />
      <retry-notification
        :show="error"
        @retry="updateData()"
      />
    </div>
    <div class="row">
      <div class="flex xs12 md4">
        <va-card :title="$t('email_templates.view')">
          <actions
            slot="actions"
            crud-links="emailTemplates"
            :actions="actions"
            :action-data="{id: $route.params.id}"
          />
          <text-list :title="$t('tables.headings.title')">
            {{ $t(emailTemplate.title) }}
          </text-list>
          <text-list v-if="emailTemplate.email_type" :title="$t('tables.headings.type')">
            {{ $t(emailTemplate.email_type.name) }}
          </text-list>
          <text-list v-if="emailTemplate.email_layout" :title="$t('tables.headings.layout')">
            {{ $t(emailTemplate.email_layout.name) }}
          </text-list>
          <text-list
            v-if="emailTemplate.region"
            :condition="currentUser.can('Regions', 'view')"
            :title="$t('tables.headings.region')"
            :label="$t(emailTemplate.region.name)"
            :to="{ name: 'regionsView', params: {id: emailTemplate.region.id}}"
          />
        </va-card>
      </div>
      <div class="flex xs12 md8">
        <va-card :title="$t('tables.headings.content')">
          <div v-html="emailTemplate.content"></div>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const TextList = () => import(/* webpackPrefetch: true */ '@/components/extras/Text/TextList')

export default {
  name: 'email-templates-view',
  components: {
    Actions,
    TextList,
  },
  data () {
    return {
      error: false,
      emailTemplate: {},
      actions: ['index', 'new', 'delete'],
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  created () {
    this.updateData()
  },
  methods: {
    routeBuilder (id) {
      return `email-templates/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const emailTemplateId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(emailTemplateId))
      } catch (err) {
        // console.log('Error fetching email template data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.emailTemplate = u.data.data
    },
    async prepareDownload (format) {
      const prepareRoute = this.routeBuilder(this.emailTemplate.id) + '?export=' + format
      const downloadRoute = 'email-templates/download/'

      return this.downloadFile(prepareRoute, downloadRoute)
    },
    async downloadFile (prepareRoute, downloadRoute) {
      let fileName = ''
      const prepare = {
        icon: 'info',
        title: this.$t('notifications.download.prepare'),
        confirmButtonText: this.$t('notifications.download.button'),
        text: this.$t('notifications.download.wait'),
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        preConfirm: async () => {
          let data = false
          try {
            data = await this.$http.get(prepareRoute)
          } catch (e) {
            this.$swal.insertQueueStep(error)
            return
          }

          fileName = data.data.data
          this.$swal.insertQueueStep(download)
        },
      }
      const error = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.error'),
      }
      const download = {
        icon: 'success',
        title: this.$t('notifications.download.downloading'),
        allowOutsideClick: () => !this.$swal.isLoading(),
        onOpen: async () => {
          this.$swal.showLoading()
          let response = false
          try {
            response = await this.$http.get(downloadRoute + fileName, {
              responseType: 'blob',
            })
          } catch (e) {
            // console.log('Cant download file', e)
            await this.$swal.queue([retry])
            return
          }

          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()

          this.$swal.close()
        },
      }
      const retry = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.failed'),
        confirmButtonText: this.$t('notifications.download.retry'),
        allowOutsideClick: true,
        preConfirm: () => {
          this.$swal.insertQueueStep(download)
        },
      }

      this.$swal.queue([prepare])
    },
  },
}
</script>
